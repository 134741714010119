
.big-title {
    margin: 40px 0 10px;
    font-size: 16px;
    padding-left: 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.goods-wrapper {
    display: flex;
    align-items: center;
    .goods-cover {
        width: 80px;
        height: 80px;
        display: flex;
        background: #F6F6F6;
        img  {
            max-width: 100%;
            max-height: 100%;
            margin: auto;
        }
    }
    .goods-name {
        width: 1%;
        flex: 1;
        margin-left: 10px;
        font-size: 16px;
    }
}
.demo-ruleForm {
    .el-form-item {
        margin-bottom: 16px;
    }
    ::v-deep .el-form-item__label {
        font-size: 16px;
        color: #333;
    }
    ::v-deep .el-form-item__error {
        padding-top: 2px;
    }
}
